<template>
  <router-link :to="itemRoute">
    <div :class="`card card-product mb-3  ${additionalItemClasses}`">
      <div class="card-body">
        <b-row>
          <b-col xs="12" md="6" lg="3">
            <div v-if="imageUrl">
              <clazy-load v-if="imageUrl" :src="imageUrl">
                <!-- The image slot renders after the image loads. -->
                <transition name="fade">
                  <img
                    :alt="`${item.Description}`"
                    :src="`${imageUrl}`"
                    crossorigin="anonymous"
                    class="rounded"
                  />
                </transition>
                <!-- The placeholder slot displays while the image is loading. -->
                <transition slot="placeholder" name="fade">
                  <!-- You can put any component you want in here. -->
                  <img
                    v-if="imageUrl"
                    alt="Placeholder"
                    crossorigin="anonymous"
                    class="rounded placeholder"
                    src="/img/placeholder.jpg"
                  />
                </transition>
              </clazy-load>

              <img
                v-if="!imageUrl && !imageUrls"
                class="d-block mx-auto"
                src="/img/itemFallback.png"
              />
            </div>
            <div
              v-if="item.Type === 'Composition' && !imageUrl"
              class="card-img-top p-2 mt-2"
            >
              <ImageGrid
                v-if="compostionImages.length"
                :images="compostionImages"
              />
            </div>
          </b-col>
          <b-col xs="12" md="6" lg="7">
            <a v-if="item.Brand" class="product-brand" href="#">{{
              item.Brand
            }}</a>

            <router-link :to="itemRoute" class="product-name">
              {{ item.Description }}
            </router-link>
            <div class="mb-2">
              <item-description
                :to="itemRoute"
                :description="item.MediumDescription"
              />
            </div>

            <div v-if="price" class="d-flex justify-content-between">
              <div class="product-price">
                <p>
                  <strong>{{ price }} {{ $t('item.euroRentalPrice') }}</strong>
                </p>
              </div>
            </div>
            <router-link
              :to="itemRoute"
              class="btn bg-primary text-white mb-2 mr-2"
              data-toggle="toast"
              data-target="#cart-toast"
            >
              <i class="czi-cart font-size-sm mr-1"></i>Bekijk details
            </router-link>
          </b-col>
        </b-row>
      </div>
    </div>
  </router-link>
</template>

<script>
// @ is an alias to /src
import ImageGrid from '@/components/items/ImageGrid'
import ItemDescription from './ItemDescription'

export default {
  name: 'Item',
  components: {
    ImageGrid,
    ItemDescription
  },
  props: {
    item: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  computed: {
    cartCount: function() {
      return this.$store.state.cartCount
    },
    availableInWarehouse: function() {
      if (this.$store.state.activeWarehouse === '') return true
      if (this.item.WarehouseIDs.includes(this.$store.state.activeWarehouse))
        return true
      return false
    },
    additionalItemClasses: function() {
      if (!this.availableInWarehouse) {
        return 'unavailable'
      } else {
        return ''
      }
    },

    image: function() {
      if (this.item.Images && this.item.Images?.length >= 1) {
        return this.item.Images[0]
      } else {
        return null
      }
    },
    user: function() {
      return this.$store.getters.user
    },
    itemRoute() {
      let name = 'uncategorizedItem'
      let params = { lang: this.$i18n.locale, itemID: this.item.ID }

      if (this.$route.params.categoryID) {
        name = 'categorizedItem'
        params.categoryID = this.$route.params.categoryID
      }

      if (this.$route.params.subCategoryID) {
        name = 'subCategorizedItem'
        params.subCategoryID = this.$route.params.subCategoryID
      }

      return {
        name,
        params
      }
    },
    imageUrl: function() {
      if (this.item.Images?.length > 0) {
        return `${process.env.VUE_APP_URL}${this.item.Images[0]}`
      } else {
        return null
      }
    },
    compostionImages: function() {
      if (this.item.Type === 'Composition') {
        let images = []

        // get first image string from all items in this.item.Items
        for (let item of this.item.Items) {
          if (item.Images && item.Images.length > 0) {
            images.push(`${process.env.VUE_APP_URL}${item.Images[0]}`)
          }
        }

        return images.slice(0, 4)
      }
      return null
    },
    imageUrls: function() {
      if (this.item.Items) {
        return this.item.Items.map(a => a.ImageUrl)
      }
      return ''
    },

    price: function() {
      if (!this.item.ItemPeriods) return null
      if (this.item.ItemPeriods[1]) {
        return this.item.ItemPeriods[1].PriceValue.toFixed(2)
          .toString()
          .replace('.', ',')
      }
      if (this.item.ItemPeriods[0]) {
        return this.item.ItemPeriods[0].PriceValue.toFixed(2)
          .toString()
          .replace('.', ',')
      }
      return null
    }
  },
  methods: {
    randomNumber: function() {
      return Math.floor(Math.random() * (11 - 99 + 1) + 99)
    }
  }
}
</script>
<style scoped lang="scss">
.unavailable {
  filter: grayscale(100%) opacity(50%);
}
img {
  cursor: pointer;
}
.card {
  background-color: rgba(0, 0, 0, 0);
  br {
    display: none;
  }
}
.placeholder {
  width: 100%;
}
.card-product {
  border: 1px solid #f0f0f0;
  padding: 1px;

  a {
    text-decoration: none;
  }

  .loaded {
    max-height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      height: auto;
      position: absolute;
    }
  }
}

.card-product:hover {
  border: 2px solid #f0f0f0;
  padding: 0px;
  cursor: pointer;
}

.product-title {
  min-height: 50px;
}

.product-name {
  font-size: 16px;
  color: #3d464d !important;
}

.product-name:hover {
  color: #25167a !important;
}

h3 {
  a {
    color: #373f50 !important;
  }
  strong {
    color: #373f50 !important;
  }
  text-decoration: none;
}
.product-brand {
  transition: color 0.25s ease-in-out;
  color: #7d879c;
  text-decoration: none !important;
  font-size: 0.75rem !important;
}

.card-img-top {
  min-height: 172px;
}
</style>
