<template>
  <div class="sidebar">
    <div class=" ml-0 sidebar-title">
      <span class="text-xl">{{ $t('sidebar.categories') }}</span>
    </div>
    <div class="mt-2">
      <div>
        <ul class="side-cats">
          <li v-for="category in categories" :key="category.CategoryID">
            <sidebar-category :category="category" :categories="categories" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import sidebarCategory from './sidebarCategory'

export default {
  components: {
    sidebarCategory
  },
  computed: {
    categories() {
      return this.$store.getters['categories/getParentCategories']
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 767px) {
  .sidebar {
    display: block !important;
  }
}

.sidebar {
  display: none;
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  .sidebar-title {
    padding: 20px 20px 0px 20px;
  }
}

.side-cat_title {
  font-weight: 900;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0px;
  width: 100%;
  position: relative;

  text-decoration: none;
  text-align: left;
}
ul.side-cats {
  font-weight: bold;
  list-style: none;
  padding-left: 0px;
  li > div > a {
    display: block;

    color: #333333 !important;
    min-height: 50px;
    line-height: 50px;
    padding-right: 55px;

    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
  }
}
</style>
